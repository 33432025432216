import React from "react"
import StandardLayout from "../layouts/standard"
import Cover from "../components/cover"
import ReactMarkdown from "react-markdown"
import moment from "moment"
import { Link } from "gatsby"

export default function BlogPostPage({ pageContext }) {
  const {
    id,
    title,
    description,
    published_at,
    thumbnail,
    content,
    blog_category,
    url,
  } = pageContext

  const { blogCategories } = require("../data.json")

  return (
    <StandardLayout title={title}>
      <div
        class="uk-cover-container"
        data-uk-height-viewport="offset-bottom: 40;"
      >
        <img src={thumbnail.publicURL} alt="" data-uk-cover />
        {/* <div class="uk-overlay uk-position-center-left uk-light">
          <div className="uk-inline">
            <div className="uk-padding blogPost uk-position-left ">
              <div className="uk-margin-small-top color-primary" style={{ fontSize: "1.2rem" }}>
                <span className="uk-margin-medium-right">
                &#8226; {moment(published_at).format("MMM Do YYYY")}
                </span>
                <span>&#8226; Insights</span>
              </div>

              <h2 className="uk-h2 uk-text-capitalize mt0" style={{ color: "black", marginTop: "15px" }}>
                {title}
              </h2>
            </div>
          </div>
        </div> */}
      </div>
      <section className="uk-section bg-lightgray">
        <div className="uk-container bg-white">
          <div className="blog-title mt4 mb1">{title}</div>
          <div className="blog-date mt1 mb1">
            {moment(published_at).format("MMM Do YYYY")} | Insights
          </div>
          <div className="blog-divider">
            <div className="blog-tags mt2">
              <p>Published in</p>
              <span>Printing</span>
              <hr />
              <p>Tagged under</p>
              <span>Blog Inspiration</span>
              <br />
              <span>Sketch</span>
              <hr />
            </div>
            <div className="mt2 mb5 raleway">
              <ReactMarkdown>{content}</ReactMarkdown>
            </div>
            <div className="more-updates">
              <div className="more-title mb2">More Updates</div>
              <div className="more-post-cont">
                {blogCategories.map(cat => {
                  //
                  return (
                    <>
                      {cat.posts.map(post => (
                        <Link
                          to={`/blogs/${cat.url}/${post.url}`}
                          className="standard-link"
                        >
                          <div className="more-post mb2">
                            <div>
                              <p className="post-title">{post.title}</p>
                              <p className="post-date">
                                {moment(post.published_at).format(
                                  "MMM Do YYYY"
                                )}{" "}
                                Printing
                              </p>
                            </div>
                            <img
                              className="post-img"
                              src={post.thumbnail && post.thumbnail.publicURL}
                            />
                          </div>
                          <hr style={{ borderColor: "black" }} />
                        </Link>
                      ))}
                    </>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </StandardLayout>
  )
}
